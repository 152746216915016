import { useCommonUserPreferences } from '@amzn/sitc-frontend/contexts';
import { CommonUserPreferences } from '@amzn/sitc-frontend/types';
import { backlotDarkTheme, backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { useMemo } from 'react';

import { getCustomTheme } from '../themes';

type ThemeProviderProps = {
  children: React.ReactNode;
  themePreference: CommonUserPreferences['theme'];
};

export const ThemeProvider = ({ children, themePreference }: ThemeProviderProps) => {
  const themeMode = useCommonUserPreferences('theme')?.name || themePreference.name;
  const backlotTheme = themeMode === 'light' ? backlotLightTheme : backlotDarkTheme;

  const theme = useMemo(() => getCustomTheme(backlotTheme), [themeMode]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
