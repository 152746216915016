export const headerHeight = 80;
export const filterHeight = 86; // -2px for the two horizontal divider lines
export const headerNameHeight = 45; // 45px is header name height
export const releaseCalendarToolbarHeight = 88;

export const pagePaddingY = '1.5rem';
export const pagePaddingX = '3.75rem';

export const tableDrawerStickyPos = headerHeight + filterHeight;
export const timelineAreaHeight = `calc(100vh - ${headerHeight + releaseCalendarToolbarHeight + 1}px)`; // 1px is for the divider line
export const chartAreaHeight = `calc(100vh - ${pagePaddingX} - ${headerHeight + filterHeight + headerNameHeight}px)`;
export const releaseSlatesTableHeight = `calc(100vh - ${pagePaddingX} - ${pagePaddingY} - ${headerHeight + headerNameHeight}px)`;

// These titles will be used to check for hidden data logic
// Future hidden titles can be append to this list
// export const HIDDEN_TITLES_LIST = ['The Rings of Power', 'Citadel'];

export const backlotImgAssetUrl = 'https://m.media-amazon.com/images/G/01/backlot/assets/img';

export const issueTicketLink = 'https://issues.amazon.com/issues/create?template=ee8630be-9c5e-4e84-bf0f-dccd1345f3a5';
export const supportLink = 'https://issues.amazon.com/issues/create?template=720908ff-5fec-486b-88e6-c1ed744dda54';

// BoxArt image
export const originalBoxArtPlaceholderUrl = `${backlotImgAssetUrl}/Amazon_MGM_Studios_Default_16x9._GR_.png`;
export const nonOriginalBoxArtPlaceholderUrl = `${backlotImgAssetUrl}/Prime_Video_Default_16x9._GR_.png`;

// Wiki
export const gapFinderModelWiki =
  'https://w.amazon.com/bin/view/Amazon_Studios/Programming_and_Audience_Development/Programming_Strategy/Unusual_Camera_Halo';
