import { createMicroAppConfigContext } from '@amzn/sitc-frontend/contexts';
import { MicroAppConfigBaseResult } from '@amzn/sitc-frontend/types';

import { duplicatorOptions } from '../configs/duplicator';

export const appDomainTemplate = `${duplicatorOptions.Arkenstone.standaloneSubdomainPrefix}.app[.{stage}].frontend-platform.studios.a2z.com`;

export type MicroAppConfigResultType = MicroAppConfigBaseResult;

export const MicroAppConfigContext = createMicroAppConfigContext<MicroAppConfigResultType>();
