export const paths = {
  index: '/',
  calendar: '/calendar',
  gapFinder: '/gap-finder',
  calendarSandbox: {
    index: '/calendar-sandbox',
    newSlate: 'new-slate',
    slate: 'slate/:id/:calendarView',
  },
  cohortReleaseCalendar: '/cohort-release-calendar',
  designations: '/designations',
  notFound: '*',
};
