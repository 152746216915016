import { createTheme, Theme } from '@mui/material/styles';

export const getCustomTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiDrawer: {
        styleOverrides: {
          paper: { paddingTop: 80 },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: theme.palette?.primary?.main,
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            borderColor: theme.palette?.primary?.main,
            height: '2rem',
            padding: '0.5rem 1rem 0.5rem 1rem',
          },
        },
      },
      // Backlot has this disabled, enable to show hover effect
      MuiIconButton: {
        defaultProps: {
          disableRipple: false,
        },
      },
      // Backlot QMD size medium to small
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: 40,
          },
          sizeSmall: {
            height: 'auto',
          },
        },
      },
    },
  });
