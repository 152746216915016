import '../i18n';

import { PageLoading } from '@amzn/sitc-frontend/components';
import {
  AlertContextProvider,
  AppAlertBannerContextProvider,
  AuthSessionProvider,
  CommonUserPreferencesProvider,
  MicroAppConfigProvider,
} from '@amzn/sitc-frontend/contexts';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { MicroAppImportProvider } from '@amzn/sitc-frontend-micro-app-sdk-core';
import { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { microAppName } from '../configs/app';
import { Stage } from '../constants/enums';
import { ApolloClientProvider } from '../contexts/Apollo';
import { ThemeProvider } from '../contexts/Theme';
import { appDomainTemplate, MicroAppConfigContext, MicroAppConfigResultType } from '../utils/app';
import { AppAccessGate } from './common/AppAccessGate';
import { FeatureConfiguration } from './FeatureConfiguration';
import { AppRoutes } from './Routes';
import { UniversalHeader } from './UniversalHeader';

const StudiosAlmanacApp: React.FC<MicroAppInitializationProps> = (props) => {
  const { appId, authSession, basePath, featureOverrides, radio, stage, userPreferences } = props;
  const themePreference = userPreferences.preferences.theme;

  const microAppImports = [{ microAppName: 'amzn-studios-universal-header-app', importPaths: ['./hooks'] }];

  return (
    <MicroAppImportProvider microAppImports={microAppImports}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename={basePath}>
          <AuthSessionProvider authSession={authSession}>
            <CommonUserPreferencesProvider userPreferences={userPreferences} radio={radio} source={microAppName}>
              <ThemeProvider themePreference={themePreference}>
                <MicroAppConfigProvider<MicroAppConfigResultType>
                  stage={stage}
                  appName={microAppName}
                  appId={appId}
                  appDomainTemplate={appDomainTemplate}
                  appLoader={<PageLoading />}
                  context={MicroAppConfigContext}>
                  <AppAccessGate stage={stage}>
                    <AlertContextProvider closeOnLocationChange>
                      <AppAlertBannerContextProvider closeOnLocationChange>
                        <ApolloClientProvider stage={stage as Stage}>
                          <FeatureConfiguration featureOverrides={featureOverrides}>
                            <UniversalHeader radio={radio as unknown as Radio} basePath={basePath} />
                            <Suspense fallback="loading...">
                              <AppRoutes stage={stage} />
                            </Suspense>
                          </FeatureConfiguration>
                        </ApolloClientProvider>
                      </AppAlertBannerContextProvider>
                    </AlertContextProvider>
                  </AppAccessGate>
                </MicroAppConfigProvider>
              </ThemeProvider>
            </CommonUserPreferencesProvider>
          </AuthSessionProvider>
        </BrowserRouter>
      </I18nextProvider>
    </MicroAppImportProvider>
  );
};

export default StudiosAlmanacApp;
