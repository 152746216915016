import { UniversalHeader as StandaloneHeader } from '@amzn/sitc-frontend/components';
import { useMicroAppImport } from '@amzn/sitc-frontend-micro-app-sdk-core';
import { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import type { UniversalHeaderProps } from '@amzn/studios-universal-header-app-shared-types';
import Box from '@mui/material/Box';
import React from 'react';

import { microAppName } from '../configs/app';
import { getUniversalHeaderConfig } from '../utils/universalHeader';

type UseUniversalHeader = (UniversalHeaderProps: UniversalHeaderProps) => void;

type Props = {
  radio: Radio;
  basePath: string;
};

export const UniversalHeader = ({ radio, basePath }: Props) => {
  const { useUniversalHeader = () => {} } =
    useMicroAppImport<{ useUniversalHeader: UseUniversalHeader }>('amzn-studios-universal-header-app', './hooks').result || {};

  const universalHeaderConfig = getUniversalHeaderConfig(basePath);

  // This Universal Header only renders on integrated mode currently.
  useUniversalHeader({ radio, microAppName, universalHeaderConfig });

  // Use minimal Universal Header in standalone mode for development purposes.
  return basePath === '/' ? <StandaloneHeader {...universalHeaderConfig} /> : <Box paddingTop="80px" />;
};
