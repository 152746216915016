import { ErrorBoundary, StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { MicroAppRegistryInfo } from '@amzn/sitc-frontend-micro-app-sdk-core';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { MUI_LICENSE_KEY } from '@amzn/studios-material-ui-x/src/globals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import StudiosAlmanacApp from './components/StudiosAlmanacApp';
import { microAppName } from './configs/app';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const radioConfig = { channelNames: ['app', 'universal-header'] };

const configuration = { appId: 'studios-portal-app' };

const microApps: MicroAppRegistryInfo[] = [
  {
    microAppName: 'amzn-studios-universal-header-app',
    packageName: '@amzn/studios-universal-header-app',
    version: '*',
    accessLevel: 'restricted',
  },
];

const MicroAppComponent = StudiosAlmanacApp;
const rootComponent = process.env.STANDALONE
  ? () => (
      <StandaloneConfiguration
        RootComponent={MicroAppComponent}
        configuration={configuration}
        microAppName={microAppName}
        microApps={microApps}
        radioConfig={radioConfig}
        standaloneModeFeatures={{ microAppUserPool: true }}
      />
    )
  : MicroAppComponent;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error, info: React.ErrorInfo) => (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <ErrorBoundary error={error} info={info} />
    </ThemeProvider>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
