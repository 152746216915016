import { en as sitcEn } from '@amzn/sitc-frontend/lang';

const treeSelect = {
  'tree_select.label': 'Country of Origin',
  'tree_select.placeholder': 'Country of Origin (ISO Code)',
  'tree_select.helper_text': '',
};

const error = {
  'error.access_denied.actionLabel': 'Ask for Access',
};

const common = {
  'common.action.add': 'Add',
  'common.action.save': 'Save',
  'common.action.saveChanges': 'Save Changes',
  'common.action.saveCadence': 'Save Cadence',
  'common.action.cancel': 'Cancel',
  'common.action.clearAll': 'Clear All',
  'common.action.close': 'Close',
  'common.action.delete': 'Delete',
  'common.alert.error': 'Error',
  'common.dialog.customCadence.helperText': 'Episodes are seperated using comma Ex: 3,1,1,1,1',
  'common.dialog.customCadence.submitTicket': "Don't see a specific cadence? Submit a ",
  'common.dialog.customCadence.troubleTicket': 'trouble ticket',
  'common.dialog.customCadence.description':
    'This season has {{totalEpisodes}} episodes. Cadence options have been limited to those that equal number of episodes.',
  'common.form.label.optional': 'Optional',
  'common.select.option.customCadence': 'Custom Cadence',
};

export const en = {
  ...sitcEn,
  ...treeSelect,
  ...error,
  ...common,
};
