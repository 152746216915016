import { AccessDeniedError } from '@amzn/sitc-frontend/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { supportLink } from '../../../constants/global';

export const AccessDenied = () => {
  const { t } = useTranslation();

  const onAction = () => {
    window.open(supportLink, '_blank', 'noreferrer');
  };

  return <AccessDeniedError actionLabel={t('error.access_denied.actionLabel')} onAction={onAction} />;
};
