import type { HeaderLink, UniversalHeaderConfig, UtilityItem } from '@amzn/studios-universal-header-app-shared-types';

import { AlmanacLogo } from '../components/common/images/AlmanacLogo';
import { paths } from '../constants/paths';

export const headerLinks: HeaderLink[] = [
  {
    primary: 'Calendar Sandbox',
    path: paths.calendarSandbox.index,
    reloadOnNav: false,
  },
  {
    primary: 'Gap Finder',
    path: paths.gapFinder,
    reloadOnNav: false,
  },
  {
    primary: 'All Releases',
    path: paths.calendar,
    reloadOnNav: false,
  },
  {
    primary: 'Dashboards',
    navContent: {
      navItems: [
        {
          primary: 'Cohort Release Calendar',
          path: paths.cohortReleaseCalendar,
        },
        {
          primary: 'Designations',
          path: paths.designations,
        },
      ],
    },
    reloadOnNav: false,
  },
];

export const helpUtility: UtilityItem = {
  key: 'help',
  iconKey: 'HelpOutline',
  primary: 'Help',
  toolTip: 'Help',
  navContents: [
    {
      key: 'help-items',
      navItems: [
        {
          iconKey: 'Description',
          primary: 'Almanac Wiki',
          secondary: 'View user guides and more information',
          newTab: true,
          path: 'https://w.amazon.com/bin/view/Amazon_Studios/Programming_and_Audience_Development/Programming_Strategy/Almanac_User_Guide/',
        },
        {
          iconKey: 'Web',
          primary: 'Support Requests',
          secondary: 'Submit a ticket to the team',
          newTab: true,
          path: 'https://issues.amazon.com/issues/create?template=720908ff-5fec-486b-88e6-c1ed744dda54',
        },
      ],
    },
  ],
};

export const getUniversalHeaderConfig = (basePath: string): UniversalHeaderConfig => ({
  disableWindowScrollElevation: true,
  productIdentifier: '',
  productIdentifierPath: basePath,
  headerLinks,
  utilities: [helpUtility /* Backlot provides default user profile utility */],
  logo: AlmanacLogo,
});
