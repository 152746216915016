import { useUserGroups } from '@amzn/sitc-frontend/contexts';
import { Action, AlmanacAppUserAuth } from '@amzn/studios-almanac-app-auth';
import React from 'react';

import { AccessDenied } from './error/AccessDenied';

type Props = {
  stage: string;
  children: React.ReactNode;
};

export const AppAccessGate = ({ stage, children }: Props) => {
  const userGroups = useUserGroups();
  const userAuth = new AlmanacAppUserAuth({ customGroups: userGroups }, { stage });
  const hasAccess = userAuth.grantUserPermission(Action.CalendarAccess);

  if (!hasAccess) {
    return <AccessDenied />;
  }

  return children;
};
