import { useUserGroups } from '@amzn/sitc-frontend/contexts';
import { Action, AlmanacAppUserAuth } from '@amzn/studios-almanac-app-auth';
import React, { lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { paths } from '../constants/paths';
import { AccessDenied } from './common/error/AccessDenied';

const ReleaseCalendar = lazy(() => import('./ReleaseCalendar'));
const Designation = lazy(() => import('./Designation'));
const CohortReleaseCalendar = lazy(() => import('./CohortReleaseCalendar'));
const GapFinder = lazy(() => import('./GapFinder'));
const CalendarSandbox = lazy(() => import('./CalendarSandbox'));
const CalendarSandboxReleaseSlates = lazy(() => import('./CalendarSandbox/ReleaseSlates'));
const CalendarSandboxNewSlate = lazy(() => import('./CalendarSandbox/NewSlate'));
const CalendarSandboxSlateCalendarView = lazy(() => import('./CalendarSandbox/SlateCalendarView'));

type RouteProps = { stage: string };

const RestrictedRoute = ({ stage }: RouteProps) => {
  const userGroups = useUserGroups();
  const userAuth = new AlmanacAppUserAuth({ customGroups: userGroups }, { stage });
  // Check access only for Gap Finder
  const isGapFinderAccessAllowed = userAuth.grantUserPermission(Action.GapFinderAccess);

  return isGapFinderAccessAllowed ? <Outlet /> : <AccessDenied />;
};

export const AppRoutes = ({ stage }: RouteProps) => (
  <Routes>
    {/* Non-Restricted Routes */}
    <Route path={paths.calendar} element={<ReleaseCalendar />} />
    <Route path={paths.cohortReleaseCalendar} element={<CohortReleaseCalendar />} />
    <Route path={paths.designations} element={<Designation />} />
    <Route path={paths.calendarSandbox.index} element={<CalendarSandbox />}>
      <Route index element={<CalendarSandboxReleaseSlates />} />
      <Route path={paths.calendarSandbox.newSlate} element={<CalendarSandboxNewSlate />} />
      <Route path={paths.calendarSandbox.slate} element={<CalendarSandboxSlateCalendarView />} />
    </Route>

    {/* Restricted Routes */}
    <Route element={<RestrictedRoute stage={stage} />}>
      <Route path={paths.gapFinder} element={<GapFinder />} />
    </Route>

    {/* Redirect to Calendar */}
    <Route path={paths.index} element={<Navigate replace to={paths.calendarSandbox.index} />} />
  </Routes>
);
