import { Stage } from '../constants/enums';

export const appSyncConfig = {
  [Stage.Dev]: {
    endpoint: 'https://asapi.devo.almanac.studios.amazon.dev/graphql',
  },
  [Stage.Beta]: {
    endpoint: 'https://asapi.beta.almanac.studios.amazon.dev/graphql',
  },
  [Stage.Gamma]: {
    endpoint: 'https://asapi.gamma.almanac.studios.amazon.dev/graphql',
  },
  [Stage.Prod]: {
    endpoint: 'https://asapi.almanac.studios.amazon.dev/graphql',
  },
};
