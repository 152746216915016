export enum Stage {
  Dev = 'dev',
  Beta = 'beta',
  Gamma = 'gamma',
  Prod = 'prod',
}

export enum DesignationTableColumn {
  Date = 'date',
  Local = 'local',
  GlobalTentpole = 'global_tentpole',
  PriorityTravelling = 'priority_travelling',
  TargetedTravelling = 'targeted_travelling',
  PriorityTravellingEngLang = 'priority_travelling_eng_lang',
  PriorityTravellingYa = 'priority_travelling_ya',
}

export enum CohortTableColumn {
  ReleaseDate = 'release_date',
  Title = 'title',
  ContentCategory = 'content_category',
  Designation = 'designation',
  MarketingCategory = 'marketing_category',
  GreenlightStatus = 'greenlight_status',
  Overall = 'overall',
  ActionAdventure = 'action_adventure',
  AdultAnimation = 'adult_animation',
  Comedy = 'comedy',
  Crime = 'crime',
  Documentary = 'documentary',
  Drama = 'drama',
  Fantasy = 'fantasy',
  Horror = 'horror',
  Kids = 'kids',
  LiveSports = 'live_sports',
  SciFi = 'sci_fi',
  UnscriptedEntertainment = 'unscripted_entertainment',
  Age18to34 = 'age_18_34',
  Age35plus = 'age_35plus',
  Men18to34 = 'men_18_34',
  Men35plus = 'men_35plus',
  Women18to34 = 'women_18_34',
  Women35plus = 'women_35plus',
}

export enum CohortAction {
  QueryVariables = 'query-variables',
  FilterTitles = 'filter-titles',
  Customize = 'customize',
  UpdateTable = 'update-table',
  CohortCocoaSegments = 'cohort-cocoa-segments',
  CohortGenreSegments = 'cohort-genre-segments',
  CohortAgeAndGenderSegments = 'cohort-age-and-gender-segments',
  CohortTitles = 'cohort-titles',
  ResetFilterTitles = 'reset-filter-titles',
  ResetCustomize = 'reset-customize',
  SortTable = 'sort-table',
}

export enum ReleaseCalendarActionType {
  FilterVariables = 'filter-variables',
  FilterEnabled = 'filter-enabled',
  ReleaseDetails = 'release-details',
  Customize = 'customize',
  RestoreFilter = 'restore-filter',
  RestoreCustomize = 'restore-customize',
}

export enum GapFinderActionType {
  QueryVariables = 'query-variables',
  FilterTitles = 'filter-titles',
  Customize = 'customize',
  GraphCustomize = 'graph-customize',
  ToggleStatus = 'toggle-status',
  TitleRows = 'title-rows',
  ProposedTitleRows = 'proposed-title-rows',
  ProposedPpar = 'proposed-ppar',
  BasePpar = 'base-ppar',
  ResetFilterTitles = 'reset-filter-titles',
  RestoreCustomize = 'restore-customize',
  RestoreGraphCustomize = 'restore-graph-customize',
  RunModelStatus = 'run-model-status',
  RunModelResults = 'run-model-results',
}

export enum CalendarSandboxActionType {
  ReleaseSlates = 'release-slates',
  NewSlateFormInput = 'new-slate-form-input',
  SlateData = 'slate-data',
  Filter = 'filter',
  TitleDetails = 'title-details',
  ResetFilter = 'reset-filter',
  UserCanEdit = 'userCanEdit',
  SelectedVersion = 'selectedVersion',
  LastModifiedTimesForVersions = 'lastModifiedTimesForVersions',
}

export enum GapFinderTableColumn {
  ShowCode = 'show_code',
  Title = 'title',
  SeasonAndPart = 'season_and_part',
  MarketingCategory = 'marketing_category',
  EarliestLaunchDate = 'earliest_launch_date',
  LatestLaunchDate = 'latest_launch_date',
  ReleaseDate = 'release_date',
  ProposedReleaseDate = 'proposed_release_date',
  Pvc = 'pvc',
  ProposedPvc = 'proposed_pvc',
  Cadence = 'cadence',
  ProposedCadence = 'proposed_cadence',
  EpisodeCount = 'episode_count',
  ProjectType = 'project_type',
  IsLiveEvent = 'is_live_event',
  ContentCategory = 'content_category',
  OriginalTerritory = 'original_territory',
}

export enum GapFinderTableColumnV2 {
  CountryCode = 'country_code',
  Tconst = 'tconst',
  Title = 'series_or_movie_name',
  SeasonNumber = 'season_number',
  StartDate = 'start_date',
  LaunchContentCategory = 'launch_content_category',
  EstimateRuntime = 'episode_or_movie_runtime',
  CadenceOrNumberOfEpisodes = 'cadence_or_number_of_episodes',
  ReleaseType = 'release_type',
  LaunchPvcForecast = 'launch_pvc_forecast',
  LaunchTpsForecast = 'launch_tps_forecast',
  LaunchFsExTpsForecast = 'launch_fs_ex_tps_forecast',
  LaunchRs180dExTpsForecast = 'launch_rs180d_ex_tps_forecast',
  CountryOfOrigin = 'country_of_origin',
}

export enum GapFinderToggleStatus {
  Table = 'table',
  Graph = 'graph',
}

export enum GapFinderModelInterval {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export enum GapFinderModel {
  Ppar = 'PPAR',
  Csf = 'CSF',
}

export enum GapFinderKpiTab {
  Pvc = 'pvc',
  Tps = 'tps',
  Fs = 'fs',
  Rs = 'rs180',
  Hpc = 'hpc',
}

export enum GapFinderGraphCustomizeKeyReleaseColor {
  Default = 'default',
  ProjectType = 'projectType',
  Marketing = 'marketing',
}

export enum DownloadStatus {
  Idle = 'idle',
  Downloading = 'downloading',
  DownloadSuccess = 'downloadSuccess',
}

export enum MetricTypeSettings {
  Default = 'Default',
  Actual = 'Actual',
  Forecast = 'Forecast',
}

export enum ReleaseFilterGroup {
  Prime = 'Prime',
  Events = 'Events',
  Competitors = 'Competitors',
}

export enum ContentCategory {
  FirstRunExclTv = '1st Run Excl TV',
  SecondRunExclTv = '2nd Run Excl TV',
  AvodMovie = 'AVOD-Movie',
  AvodTv = 'AVOD-TV',
  ChannelsMovie = 'Channels-Movie',
  ChannelsTv = 'Channels-TV',
  Dts = 'DTS',
  IntlOriginalMovies = 'Intl Original Movies',
  IntlOriginalTv = 'Intl Original TV',
  KidsTv = 'Kids TV',
  LibraryMovies = 'Library Movies',
  LibraryTv = 'Library TV',
  PvdAdultTv = 'PVD Adult TV',
  PvdKidsTv = 'PVD Kids TV',
  PvdMovies = 'PVD Movies',
  Pay1Movies = 'Pay 1 Movies',
  Pay2Movies = 'Pay 2 Movies',
  TvodEstMovie = 'TVOD-EST-Movie',
  TvodEstTv = 'TVOD-EST-TV',
  TvodPoestMovie = 'TVOD-POEST-Movie',
  TvodVodMovie = 'TVOD-VOD-Movie',
  TvodVodTv = 'TVOD-VOD-TV',
  UsOriginalMovies = 'US Original Movies',
  UsOriginalTv = 'US Original TV',
  Uncategorized = 'Uncategorized',
  LiveEvents = 'Live Events',
  LiveSports = 'Live Sports',
}

export enum MarketingCategory {
  Tentpole = 'Tentpole',
  Secondary = 'Secondary',
  Portfolio = 'Portfolio',
  Core = 'Core',
  Na = 'N/A',
}

export enum DateFidelity {
  Locked = 'Locked',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
}

export enum Designation {
  GlobalTentpole = 'Global Tentpole',
  PriorityTravelling = 'Priority Travelling',
  TargetedTravelling = 'Targeted Travelling',
  Local = 'Local',
  Na = 'N/A',
}

export enum ServiceType {
  Channels = 'Channels',
  Freevee = 'Freevee',
  Fvod = 'FVOD',
  Pvd = 'PVD',
  Svod = 'SVOD',
  Theatrical = 'Theatrical',
  Tvod = 'TVOD',
}

export enum SlateServiceType {
  Channels = 'Channels',
  Freevee = 'Freevee',
  Svod = 'SVOD',
  Tvod = 'TVOD',
}

export enum ReleaseType {
  Blank = 'Blank',
  Movie = 'Movie',
  Season = 'Season',
}

export enum EventType {
  Blank = 'Blank',
  Sports = 'Sports',
  CulturalEvent = 'Cultural Event',
  Holiday = 'Holiday',
  AwardShow = 'Award Show',
  Other = 'Other',
}

export enum ReleasePlatform {
  Blank = 'Blank',
  Netflix = 'Netflix',
  HboMax = 'HBO Max',
  OtherVod = 'Other VOD',
  DisneyPlus = 'Disney Plus',
  Apple = 'Apple',
  ParamountPlus = 'Paramount Plus',
  DiscoveryPlus = 'Discovery Plus',
  Hulu = 'Hulu',
  Peacock = 'Peacock',
}

export enum ContentType {
  Blank = 'Blank',
  Movie = 'Movie',
  Series = 'Series',
}

export enum FirstReleaseStrategy {
  Blank = 'Blank',
  Vod = 'VOD',
  Theatrical = 'Theatrical',
  Linear = 'Linear',
}

export enum NoteworthyRating {
  Blank = 'Blank',
  Rating1 = 'Rating 1',
  Rating2 = 'Rating 2',
  Rating3 = 'Rating 3',
  NotRated = 'Not Rated',
}

export enum PrimeDetailsTab {
  Release = 'release',
  Cohort = 'cohort',
  Other = 'other',
}

export enum ReleaseCustomize {
  RowDensity = 'rowDensity',
  PrimeRelease = 'primeRelease',
  CompetitorRelease = 'competitorRelease',
}

export enum ReleaseCustomizeRowDensity {
  Default = 'default',
  Tight = 'tight',
}

export enum ReleaseCustomizeMarketingCategoryValue {
  FullCategoryName = 'Full Category Name',
  CategoryAbbreviation = 'Category Abbreviation',
}

export enum Cadence {
  Movie = 'Movie',
  Binge = 'Binge',
  SplitSeasonBinge = 'Split-Season Binge',
  Episodic = 'Episodic',
  // DayAfterBroadcast = 'Day After Broadcast',
  // Weekly = 'Weekly',
}

export enum AudienceTag {
  W18to34 = 'W 18-34',
  M18to34 = 'M 18-34',
  A18Plus = 'A 18+',
  A35Plus = 'A 35+',
}

export enum GroupPvc {
  OneFiveTenTwenty = '1-5, 5-10, 10-20, 20+',
  OneTenTwenty = '1-10, 10-20, 20+',
}

export enum CustomBucketGroupPvc {
  OneFive = '1-5',
  FiveTen = '5-10',
  TenTwenty = '10-20',
  TwentyPlus = '20+',
}

export enum CocoaGenre {
  ActionAdventure = 'Action Adventure',
  AdultAnimation = 'Adult Animation',
  Comedy = 'Comedy',
  Crime = 'Crime',
  Documentary = 'Documentary',
  Drama = 'Drama',
  Fantasy = 'Fantasy',
  Horror = 'Horror',
  Kids = 'Kids',
  LiveSports = 'Live Sports',
  SciFi = 'Sci-Fi',
  UnscriptedEntertainment = 'Unscripted Entertainment',
  Na = 'N/A',
}

export enum HubPriorityDesignation {
  FeatureRotator = 'Feature Rotator',
  FinaleSupport = 'Finale Support',
  HubPriorityContent = 'Hub Priority Content',
  Portfolio = 'Portfolio',
  Secondary = 'Secondary',
  TargetedTentpole = 'Targeted Tentpole',
}

export enum SlateReleaseType {
  Movie = 'Movie',
  Series = 'Series',
}

export enum SlateView {
  Horizontal = 'horizontal',
  Promise = 'promise',
}

export enum ReleaseColor {
  DarkViolet = 'Dark Violet',
  Violet = 'Violet',
  Pink = 'Pink',
  Magenta = 'Magenta',
  BloodOrange = 'Blood Orange',
  Orange = 'Orange',
  LightOrange = 'Light Orange',
  Amber = 'Amber',
  Yellow = 'Yellow',
  LightGreen = 'Light Green',
  Green = 'Green',
  Cyan = 'Cyan',
  Blue = 'Blue',
  LightBlue = 'Light Blue',
}

export enum ReleaseBorder {
  None = 'None',
  Solid = 'Solid',
  Thick = 'Thick',
  Dotted = 'Dotted',
  Dashed = 'Dashed',
  Double = 'Double',
}

export enum StyleConfig {
  None = 'None',
  ByAttribute = 'ByAttribute',
}

export enum StyleType {
  Border = 'border',
  Color = 'color',
}

export enum SlateDialogStatus {
  AbandonChanges = 'abandon_changes',
  View = 'view',
  Edit = 'edit',
  Share = 'share',
  Delete = 'delete_slate',
  Remove = 'remove_slate',
  RevisionHistory = 'revisionHistory',
}

export enum PromiseRowDensity {
  Default = 'default',
  Wide = 'wide',
  Wider = 'wider',
}

export enum SlateNoteworthyRanking {
  One = '1',
  Two = '2',
  Three = '3',
  NotRated = 'Not_Rated',
}

export enum SlateReleasePlatform {
  Apple = 'Apple',
  DiscoveryPlus = 'DISCOVERY_PLUS',
  DisneyPlus = 'DISNEY_PLUS',
  HboMax = 'HBO_MAX',
  Hulu = 'HULU',
  Netflix = 'NETFLIX',
  OtherVod = 'OTHER_VOD',
  ParamountPlus = 'PARAMOUNT_PLUS',
  Peacock = 'PEACOCK',
}

export enum SlateCompetitorReleases {
  Territories = 'competitor_territories',
  NoteworthyRatings = 'competitor_ratings',
  ReleasePlatforms = 'competitor_release_platforms',
}

export enum SlateFilterTitles {
  ShowHideTitles = 'showHideTitles',
  ApplyFilters = 'applyFilters',
}

export enum SlateRegion {
  UsGlobal = 'US/Global',
  Apac = 'APAC',
  Emea = 'EMEA',
  Latcanz = 'LATCANZ',
}

export enum SlateTitleProgram {
  Global = 'Global',
  Local = 'Local',
  Ohc = 'OHC',
}

export enum CalendarEventContentType {
  Title = 'Title',
  Event = 'Event',
}
